import React, { setState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import BookInfo from "../components/BookInfo"
import Image from "gatsby-image"
import { useSpring, animated } from "react-spring"
import { FiShoppingCart } from "react-icons/fi"
import { useStore } from "../store"
import slugify from "slugify"
import { Link } from "gatsby"
import useBooks from "../hooks/useBooks"
import Llibre from "../components/Book"
import SEO from "../components/seo"
import uniqBy from "lodash/uniqBy"
import {
  FacebookIcon,
  TwitterIcon,
  FacebookShareButton,
  WhatsappIcon,
  TwitterShareButton,
  WhatsappShareButton,
  EmailIcon,
  EmailShareButton,
} from "react-share"
import { SRLWrapper } from "simple-react-lightbox"

export const pageQuery = graphql`
  query($title: String!) {
    markdownRemark(frontmatter: { title: { eq: $title } }) {
      frontmatter {
        title
        novetat
        autors
        pagines

        format
        external_links
        num
        ebook
        lektu
        isbn
        genere
        tags
        preu
        imatge {
          sharp: childImageSharp {
            fluid(maxWidth: 960, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
              src
            }
          }
          small: childImageSharp {
            fluid(maxWidth: 150, maxHeight: 150, quality: 80) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        edicio
        colleccio
        descripcio
      }
    }
  }
`

const Book = ({ data: { markdownRemark: book } }) => {
  const { addBookToCart } = useStore(state => state.actions)
  const { cart } = useStore(state => state)

  const books = useBooks()
  const {
    colleccio,
    autors,
    title,
    tags,
    format,
    imatge,
    genere,
    pagines,
    isbn,
    num,
    preu,
    ebook,
    lektu,
    descripcio,
  } = book.frontmatter

  const props = useSpring({
    opacity: 1,
    marginTop: 0,
    from: { opacity: 0, marginTop: 300 },
  })
  const propsTitle = useSpring({
    opacity: 1,
    marginLeft: 0,
    from: { opacity: 0, marginLeft: 300 },
  })
  const propsImage = useSpring({
    transform: "scale(1.2)",
    from: { transform: "scale(1)" },
  })

  const shareUrl = `https://www.editorialmalesherbes.com/cataleg/${slugify(
    title,
    {
      lower: true,
    }
  )}`

  const similarBooks = () => {
    let p = []
    let count = 0
    const _tags = tags?.map(e => slugify(e, { lower: true }).trim())

    const b = books.map(e => {
      e.count = 0

      if (autors[0] === e.autors[0]) {
        if (e.title !== title) {
          e.count++
        }
        if (e.count >= 1) {
          p.push(e)
        }
      }

      e?.tags?.forEach(t => {
        if (_tags.includes(slugify(t, { lower: true }).trim())) {
          if (e.title !== title) {
            e.count++
          }
          if (e.count > 2) {
            p.push(e)
          }
        }
      })
    })

    return {
      books: uniqBy(p, e => e.uid).map(e => (
        <div key={book.title} className="w-1/2 md:w-1/4 px-2">
          <Llibre {...e} />
        </div>
      )),
      leng: uniqBy(p, e => e.uid).length,
    }
  }

  return (
    <Layout>
      <SEO
        type="cataleg"
        title={`${title} de ${autors.map(e => e)}-llibre ${colleccio}`}
        description={descripcio.substring(0, 200)}
        lang="ca"
        image={`https://wwww.editorialmalesherbes.com/${imatge.sharp.fluid.src}`}
        isbn={isbn}
        autor={autors[0]}
        pagines={pagines}
        nomLlibre={title}
        preu={preu}
        keywords={[
          "escriptor",
          "llibres",
          "literatura catalana",
          "terror",
          "ciència ficció",
          "neogrotesc",
          "clàssics",
          "surrealisme",
          "editors independents",
          "males herbes",
          "editorial",
          autors.map(e => e),
          colleccio,
          tags.map(e => e),
          "narrativa",
          "català",
          "catalana",
          "autor",
          "autora",
          "escriptora",
        ]}
      />
      <div className="flex flex-wrap">
        <div className="w-full">
          <p className="font-title font-bold uppercase text-xl border-b border-black  py-2">
            Catàleg
          </p>
        </div>
      </div>
      <animated.div style={propsTitle}>
        <div className="w-full items-center mt-8">
          <h2 className="text-3xl font-medium italic">{title}</h2>

          <h3 className="text-3xl -mt-2">{autors.join(" i ")}</h3>

          <h5 className="text-3xl nums-oldstyle -mt-2 caps-small text-malesherbes font-title">
            {parseFloat(preu).toFixed(2)} €
          </h5>
        </div>
      </animated.div>

      <div className="flex flex-wrap -mx-3 md:-mx-4 mt-12">
        <div class="w-full px-4 xs:w-1/2 sm:w-1/2 md:w-1/4 xl:w-1/4 lg:w-1/4">
          <SRLWrapper>
            <Image
              className="w-full cursor-pointer"
              fluid={imatge.sharp.fluid}
              alt={`Coberta del llibre | ${title} de ${autors.map(
                a => a
              )} | Editorial Males Herbes`}
            />
          </SRLWrapper>
          {cart.filter(
            e =>
              slugify(e.title, { lower: true }) ===
              slugify(title, { lower: true })
          ).length === 0 ? (
            <animated.div style={props}>
              {
                <button
                  onClick={() =>
                    addBookToCart(
                      Object.assign({ id: Date.now() }, book.frontmatter)
                    )
                  }
                  className="bg-malesherbes w-1/2 md:w-full 
                font-title hover:bg-pagination uppercase text-black 
                text-center py-2 rounded-sm px-4 mt-6 mb-8 align-center"
                >
                  <p>Comprar</p>
                </button>
              }
              {ebook && (
                <p className="mt-2  mb-8">
                  Pots comprar l'ebook{" "}
                  <span className="italic font-medium">{title}</span> a{" "}
                  <a
                    href={lektu}
                    target="_blank"
                    className="font-semibold hover:underline"
                  >
                    Lektu
                  </a>
                </p>
              )}
            </animated.div>
          ) : (
            <animated.div style={props}>
              <p className="font-title mt-2  mb-8">
                Has afegit{" "}
                <i>
                  <b>{title}</b>
                </i>{" "}
                a la teva cistella.
              </p>
            </animated.div>
          )}
        </div>
        <div class="w-full md:w-3/4 px-4 mb-16  text-lg">
          <div class="w-full h-auto">
            {" "}
            <p className="font-bold align-top -my-1">Descripció</p>
            <div
              className="mt-6 text-lg"
              dangerouslySetInnerHTML={{
                __html: descripcio.replace(/\n/g, "<br/>"),
              }}
            />
            <p className="font-bold align-top mt-6">Detalls</p>
            <p className="font-medium mt-6">
              {genere === "Home" &&
                (autors.length > 1 ? `Autors: ` : `Autor: `)}
              {genere === "Dona" &&
                (autors.length > 1 ? `Autores: ` : `Autora: `)}
              {genere === "NB" && `Autors`}
              {autors.map((e, i) => {
                return (
                  <span className="font-normal">
                    <Link
                      className="hover:underline cursor-pointer"
                      to={`/autors/${slugify(e, { lower: true })}`}
                    >
                      {e}{" "}
                    </Link>
                    {i === autors.length - 1 ? `` : ` i `}
                  </span>
                )
              })}
            </p>
            <p className="font-medium">
              Col·lecció:{" "}
              <span className="font-normal">
                {colleccio} {num !== 0 ? `nº${num}` : ``}
              </span>
            </p>
            <p className="font-medium">
              Pàgines: <span className="font-normal">{pagines} pàg</span>.
            </p>
            <p className="font-medium">
              Format{`${ebook ? `s` : ``}`}:{" "}
              <span className="font-normal">
                {format}{" "}
                <span className="normal-case">{ebook && `i e-book`}</span>
              </span>
            </p>
            <p className="font-medium">
              ISBN: <span className="font-normal">{isbn}</span>
            </p>
            <p className="capitalize mt-2 italic">{tags.join(", ")}</p>
          </div>
          <br />
          <p className="mb-4 font-medium">
            Comparteix <i>{title}</i> de{" "}
            {autors.map((e, i) => {
              return (
                <span className="font-normal">
                  <Link
                    className="hover:underline cursor-pointer"
                    to={`/autors/${slugify(e, { lower: true })}`}
                  >
                    {e}{" "}
                  </Link>
                  {i === autors.length - 1 ? `` : ` i `}
                </span>
              )
            })}
          </p>
          <FacebookShareButton url={shareUrl}>
            <FacebookIcon className="w-8 h-8 rounded-full mr-1" />
          </FacebookShareButton>
          <TwitterShareButton
            url={shareUrl}
            title={title}
            via={"editorialmalesherbes"}
            hashtags={[
              "editorialmalesherbes",
              `${autors[0].replace(" ", "").toLowerCase()}`,
              `${tags.map(e => e.replace(" ", "").toLowerCase())}`,
            ]}
          >
            <TwitterIcon className="w-8 h-8 rounded-full mr-1" />
          </TwitterShareButton>
          <WhatsappShareButton title={title} separator={" - "} url={shareUrl}>
            <WhatsappIcon className="w-8 h-8 rounded-full mr-1" />
          </WhatsappShareButton>
          <EmailShareButton
            subject={`Editorial Males Herbes, ${title} de ${autors.map(
              e => e
            )}`}
            body={`Llibre de la Editorial Males Herbes, ${title} de ${autors.map(
              e => e
            )}, ${tags.map(e => e)}`}
            separator={" - "}
            url={shareUrl}
          >
            <EmailIcon className="w-8 h-8 rounded-full mr-1" />
          </EmailShareButton>
        </div>
      </div>

      {similarBooks().leng > 0 && (
        <>
          <div className="w-full">
            <p className="font-title border-b border-black py-2 font-semibold">
              Llibres similars
            </p>
          </div>
          <div className="flex flex-wrap -mx-2 mt-12 -my-4">
            {similarBooks().books}
          </div>
        </>
      )}
    </Layout>
  )
}

export default Book
